import React from 'react'
import { Col, Layout, Row, Space, Table, Typography } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import BirthRecord from '../BirthRecord/BirthRecord';
import SyncplicityFiles from '../SyncplicityFiles/SyncplicityFiles';
import SyncplicityLogs from '../SyncplicityLogs/SyncplicityLogs';
import RecrdsSubNavBar from '../Shared/RecrdsSubNavBar';
import { useUserStore } from '../../context/UserStore';

const { useState, useEffect } = React;
const { Header, Content } = Layout;
const { Text, Title } = Typography;

export default function SyncplicityFolder(props) {
    dayjs.extend(utc);
    let { id } = useParams();
    const [syncplicityFolder, setSyncplicityFolder] = useState({
        attributes: {}
    });

    const [syncplicityFiles, setSyncplicityFiles] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const user = useUserStore((state) => state.user);

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}syncplicity_folders/${id}`, {
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            setIsLoading(false);
            setSyncplicityFolder(response.syncplicity_folder.data)
        }).catch(error => {
            console.log(error)
        });
    };



    useEffect(() => {
        customFetch({});
    }, []);


    return (
        <Layout>
            <RecrdsSubNavBar />
            <Content>
                {(isLoading ? <LoadingSpinner /> :
                    <>
                        <Row>
                            <Col xs={12}>
                                <Header style={{ backgroundColor: '#ffffff' }}>
                                    <Row>
                                        <Col xs={12}>
                                            <Title>Syncplicity Record</Title>
                                        </Col>
                                    </Row>
                                </Header>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                        <p><Text strong>Birth Record ID:</Text> {syncplicityFolder.attributes.name}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                        <p><Text strong>Folder ID:</Text> {syncplicityFolder.attributes.folder_id}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                        <p><Text strong>Deleted:</Text> {(syncplicityFolder.attributes.deleted ? <Text >True</Text> : <Text type="success">False</Text>)}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                        <p><Text strong>Empty Folder:</Text> {(syncplicityFolder.attributes.empty_folder ? <Text >True</Text> : <Text type="success">False</Text>)}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                        <p><Text strong>Error:</Text> {(syncplicityFolder.attributes.error ? <Text >True</Text> : <Text type="success">False</Text>)}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                        <p><Text strong>In Error Folder:</Text> {(syncplicityFolder.attributes.in_error_folder ? <Text >True</Text> : <Text type="success">False</Text>)}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                        <p><Text strong>Migrated:</Text> {(syncplicityFolder.attributes.migrated ? <Text type="success">True</Text> : <Text >False</Text>)}</p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                        <p><Text strong>Updated At</Text> {dayjs.utc(syncplicityFolder.attributes.updated_at, "YYYY-MM-DD").format("MM/DD/YYYY")}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12}>
                                {(syncplicityFolder.attributes.name && <BirthRecord syncplicity_folder_id={syncplicityFolder.id} />)}
                            </Col>
                        </Row>
                        {(syncplicityFolder.attributes.name && <SyncplicityFiles syncplicity_folder_id={syncplicityFolder.id} />)}
                        {(syncplicityFolder.attributes.name && <SyncplicityLogs syncplicity_folder_id={syncplicityFolder.id} />)}
                    </>
                )}
            </Content>
        </Layout>
    )
}
