import React from 'react'
import { Button, Col, Input, Layout, Row, Select, Space, Table, Typography } from 'antd';
import { SearchOutlined, EditOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../Providers/UserStore';
import useToken from '../useToken';
import RecrdsSubNavBar from '../Shared/RecrdsSubNavBar';
import { useUserStore } from '../../context/UserStore';

const { useState, useEffect } = React;
const { Header, Content } = Layout;
const { Text, Title } = Typography;

export default function ErrorRecords() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorRecords, setErrorRecords] = useState([]);
    const [searchObject, setSearchObject] = useState({
        folder_id: '',
        password: '',
        concat_error_messages: ''
    });
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [sort, setSort] = useState('id');
    const [order, setOrder] = useState('asc');
    const [total, setTotal] = useState(1);

    const user = useUserStore((state) => state.user);

    const errorRecordPage = (id) => {
        navigate(`/error_record/${id}`);
    }

    const customFetch = async (page, pageSize) => {
        typeof page === 'object' ? page = 1 : page = page;
        typeof pageSize === 'undefined' ? pageSize = 25 : pageSize = pageSize;
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}error_records?page=${page}`
            + `&per_page=${pageSize}`
            + `&folder_id=${searchObject.folder_id.trim()}`
            + `&password=${searchObject.password.trim()}`
            + `&concat_error_messages=${searchObject.concat_error_messages}`
            + `&sort=${sort} &order=${order}`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {

            const newData = response.error_records.data.map((item) => {
                return {
                    ...item,
                    key: item.id
                }
            })
            setErrorRecords(newData);
            setTotal(response.total)
            setPage(response.current_page);
            setPageSize(pageSize);
            setIsLoading(false);
        }).catch(error => {
            console.log(error)
        });

    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            sorter: (a, b) => (a.id > b.id ? 1 : -1),
            render: (id) => `${id}`,
            width: "5%"
        },
        {
            title: 'Folder ID',
            dataIndex: 'attributes.folder_id',
            sorter: (a, b) => (a.folder_id > b.folder_id ? 1 : -1),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search by Folder ID"
                            onPressEnter={() => {
                                customFetch(page, pageSize);
                                confirm();
                            }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    folder_id: e.target.value
                                }))
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                // confirm({ closeDropDown: false });
                            }
                            }
                        />
                        <Space>
                            <Button
                                onClick={() => {
                                    customFetch(page, pageSize);
                                    confirm();
                                }}

                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => { clearFilters() }}

                                className="btn btn-default">
                                Reset
                            </Button>
                        </Space>
                    </div>
                )
            },
            onFilterDropdownOpenChange: (visible) => {
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.attributes.folder_id.toLowerCase().includes(value.toLowerCase()),
            render: (id, record) => {
                return `${record.attributes.folder_id}`
            }
        },
        {
            title: 'Birth Record ID (if known)',
            dataIndex: 'attributes.name',
            sorter: (a, b) => (a.name > b.name ? 1 : -1),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search by Birth Record ID"
                            onPressEnter={() => {
                                customFetch(page, pageSize);
                                confirm();
                            }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    name: e.target.value
                                }))
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                // confirm({ closeDropDown: false });
                            }
                            }
                        />
                        <Space>
                            <Button
                                onClick={() => {
                                    customFetch(page, pageSize);
                                    confirm();
                                }}

                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => { clearFilters() }}

                                className="btn btn-default">
                                Reset
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.attributes.name.toLowerCase().includes(value.toLowerCase()),
            render: (id, record) => {
                return `${record.attributes.name}`
            }
        },
        {
            title: 'Error Count',
            dataIndex: 'attributes.error_count',
            render: (id, record) => {
                return `${record.attributes.error_count}`
            }
        },
        {
            title: 'Error Reasons',
            dataIndex: 'attributes.concat_error_messages',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Select
                            style={{ width: 120 }}
                            placeholder="Select Error Reason"
                            defaultValue={selectedKeys}
                            onChange={(value) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    concat_error_messages: value
                                }))
                                setSelectedKeys(value ? [value] : []);
                            }}
                            options={
                                [
                                    {
                                        label: 'No Error',
                                        value: '',
                                    },
                                    {
                                        label: 'Duplicate Folder',
                                        value: 'duplicate folders found',
                                    },
                                    {
                                        label: 'No XML File',
                                        value: 'no xml files found',
                                    },
                                    {
                                        label: 'Corrupt XML File',
                                        value: 'XML Corrupted'
                                    }
                                ]
                            }
                        />
                        <Space>
                            <Button
                                onClick={() => {
                                    customFetch(page, pageSize);
                                    confirm();
                                }}

                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                        </Space>
                    </div>
                )
            },
            render: (id, record) => {
                return `${record.attributes.concat_error_messages}`
            }
        },
        {
            title: 'Folder Path',
            dataIndex: 'attributes.folder_path',
            render: (id, record) => {
                return `${record.attributes.folder_path}`
            }
        },
        {
            title: 'Created At',
            dataIndex: 'attributes.created_at',
            sorter: (a, b) => (a.created_at > b.created_at ? 1 : -1),
            render: (id, record) => {
                return `${dayjs(record.attributes.created_at).format('MM/DD/YYYY')}`
            }
        },
        {
            title: 'Updated At',
            dataIndex: 'attributes.updated_at',
            sorter: (a, b) => (a.updated_at > b.updated_at ? 1 : -1),
            render: (id, record) => {
                return `${dayjs(record.attributes.updated_at).format('MM/DD/YYYY')}`
            }
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (id, record) => {
                return (
                    <Button onClick={() => errorRecordPage(record.id)}>View Record</Button>
                )
            }
        }
    ]

    useEffect(() => {
        customFetch({});
    }, []);


    const navigate = useNavigate();

    return (
        <Layout>
            <RecrdsSubNavBar />
            <Header style={{ backgroundColor: '#ffffff' }}>
                <Row>
                    <Col xs={12}>
                        <Title>Error Folders</Title>
                    </Col>
                </Row>
            </Header>
            <Content>
                <Row>
                    <Col xs={12} offset={1}>
                        <p>Error Folders that for some reason or other, have been found to have one or more errors.</p>
                        <p>Total Records Count: {total}</p>
                    </Col>
                </Row>
                <Table
                    style={{ display: 'flex', flex: 1, margin: 10, width: '100%' }}
                    columns={columns}
                    dataSource={errorRecords}
                    loading={isLoading}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange: (page, pageSize) => {
                            customFetch(page, pageSize)
                        }
                    }}
                    rowKey="id"
                />
            </Content>
        </Layout>
    )
}
