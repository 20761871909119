import React from 'react'
import { Col, Layout, Row, Typography } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import useToken from '../useToken';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useUserStore } from '../../context/UserStore';

const { useState, useEffect } = React;
const { Header, Content } = Layout;
const { Text, Title } = Typography;

export default function BirthRecord(props) {
    dayjs.extend(utc);
    const { token, setToken } = useToken();
    const [birthRecord, setBirthRecord] = useState({
        attributes: {}
    });
    const [birthRecordErrorLog, setBirthRecordErrorLog] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const user = useUserStore((state) => state.user);

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}syncplicity_folders/${props.syncplicity_folder_id}/birth_records`, {
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            setIsLoading(false);
            setBirthRecord(response.birth_record.data)
        }).catch(error => {
            console.log(error)
        });
    };

    const formatDateTimeForDisplay = (dateTime) => {
        const datetimeString = dateTime;
        const date = dayjs.utc(datetimeString);
        return date.format("MM/DD/YYYY hh:mm A")
    }



    useEffect(() => {
        customFetch({});
    }, []);


    return (
        <Layout>
            <Header style={{ backgroundColor: '#ffffff' }}>
                <Row>
                    <Col xs={24}>
                        <Title>Birth Record</Title>
                    </Col>
                </Row>
            </Header>
            <Content>
                {(isLoading ? <LoadingSpinner /> :
                    <>
                        {(
                            ((birthRecord !== null) && (birthRecord.attributes !== undefined)) ?
                                <>
                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                            <p><Text strong>Birth Record ID:</Text> {birthRecord.attributes.record_id}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                            <p><Text strong>Bereavement:</Text> {(birthRecord.attributes.is_bereavement ? <Text type="success">True</Text> : <Text >False</Text>)}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                            <p><Text strong>Birth Date:</Text> {formatDateTimeForDisplay(birthRecord.attributes.born_at)}</p>
                                            <p><Text strong>Captured Date:</Text> {formatDateTimeForDisplay(birthRecord.attributes.captured_at)}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                            <p><Text strong>CSN:</Text> {birthRecord.attributes.csn}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                            <p><Text strong>Birth Length:</Text> {birthRecord.attributes.length} {birthRecord.attributes.length_unit} / {birthRecord.attributes.length_converted}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                            <p><Text strong>Hospital ID:</Text>{birthRecord.attributes.hospital_id}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                            <p><Text strong>Birth Weight:</Text> {birthRecord.attributes.weight} {birthRecord.attributes.weight_unit} / {birthRecord.attributes.weight_converted}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                            <p><Text strong>Password:</Text> {birthRecord.attributes.password}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                            <p><Text strong>In Synplicity:</Text> {(birthRecord.attributes.syncplicity ? <Text type="success">True</Text> : <Text >False</Text>)}</p>
                                        </Col>
                                    </Row>
                                </>
                                : <Text >No Birth Record Found</Text>
                        )}
                    </>
                )}
            </Content>
        </Layout>
    )
}
