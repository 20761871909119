import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, notification } from 'antd';
import './Login.css';
import { useUserStore } from '../../context/UserStore';

export default function Login({ setToken }) {
    const [email, setEmail] = useState();
    const setMe = useUserStore((state) => state.setMe);
    const setUser = useUserStore((state) => state.setUser);
    const [password, setPassword] = useState();
    const navigate = useNavigate();

    const getCurrentUser = async (user) => {
        return fetch(`${process.env.REACT_APP_API_URL}users/me`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
            }
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res);
                }
            })
            .then((json) => {
                console.log(json.user);
                setMe(json.user);
                // navigate("/");
                // window.location.reload(false);
            })
            .catch((err) => {
                notification.error({
                    message: 'Login Failed',
                    description: 'Please check your credentials and try again.',
                });
            });
    };

    const loginUser = async (credentials) => {
        return fetch(`${process.env.REACT_APP_API_URL}users/sign_in`, {
            method: "post",
            headers: {
                'Authorization': 'cst_live_eDuhCB71pRSMhHYRT8uHZ5V5',
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res);
                }
            })
            .then((json) => {
                getCurrentUser(json.user)
                setUser(json.user);
                navigate("/dashboard");
                window.location.reload(false);
            })
            .catch((err) => {
                console.log('err: ', err);
                notification.error({
                    message: 'Login Failed',
                    description: 'Please check your credentials and try again.',
                });
            });
    }

    const onFinish = (values) => {
        loginUser({ user: { email: values.email, password: values.password } });
    };

    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Failed to Update',
            description: 'Please correct the errors and try again.',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const onFinishForgotPasswordFailed = (errorInfo) => {
        notification.error({
            message: 'Failed to Send Password Reset Email',
            description: 'Please correct the errors and try again.',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const onFinishForgotPassword = (values) => {
        resetPassword({ user: { email: values.email } });
    };

    const resetPassword = async (values) => {
        return fetch(`${process.env.REACT_APP_API_URL}users/forgot_password`, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res);
                }
            })
            .then((json) => {
                notification.success({
                    message: 'Request Received',
                    description: 'Your request was received. If the email exists in our system, you will receive an email with instructions to reset your password.',
                });
            })
            .catch((err) => {
                notification.error({
                    message: 'Request Received',
                    description: 'Your request was received. If the email exists in our system, you will receive an email with instructions to reset your password.',
                });
            });
    };

    return (
        <div className="container mx-auto mb-4">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Login</h3>
                        <p className="mt-1 text-sm text-gray-600">
                            Please login to your account.
                        </p>
                    </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-3 sm:col-span-2">
                                        <Form.Item
                                            label="Email Address"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your email address.',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-3 sm:col-span-2">
                                        <Form.Item
                                            label="Password"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your password.',
                                                },
                                            ]}
                                        >
                                            <Input.Password visibilityToggle={true} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <Form.Item>
                                    <Button

                                        htmlType="submit"
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>

                            </div>
                        </div>
                    </Form>
                </div>
                <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Forgot Password?</h3>
                        <p className="mt-1 text-sm text-gray-600">
                            Please enter your email address to reset your password.
                        </p>
                    </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                    <Form
                        onFinish={onFinishForgotPassword}
                        onFinishFailed={onFinishForgotPasswordFailed}
                    >
                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-3 sm:col-span-2">
                                        <Form.Item
                                            label="Email Address"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your email address.',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <Form.Item>
                                    <Button
                                        htmlType="submit"
                                    >
                                        Reset Password
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}