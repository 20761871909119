import React from 'react'
import BirthRecords from '../BirthRecords/BirthRecords';

export default function Dashboard() {
  return (
    <>
      <BirthRecords></BirthRecords>
    </>
  )
}
