import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

const items = [
    {
        label: 'Birth Records',
        key: 'birth_records',
    },
    {
        label: 'Syncplicity Folders',
        key: 'syncplicity_folders'
    },
    {
        label: 'Missing Records',
        key: 'missing_records'
    }
]

const onClick = ({ key }) => {
    console.log(key);
};

export default function RecrdsSubNavBar() {
    const navigate = useNavigate();

    const changePage = (page) => {
        navigate(`/${page}`);
    }

    return (
        <Row>
            <Col>
                <Button type='link' onClick={() => changePage('birth_records')}>
                    Birth Records
                </Button>
            </Col>
            <Col>
                <Button type='link' onClick={() => changePage('error_records')}>
                    Error Records
                </Button>
            </Col>
            <Col>
                <Button type='link' onClick={() => changePage('missing_records')}>
                    Missing Records
                </Button>
            </Col>
            <Col>
                <Button type='link' onClick={() => changePage('reindex_records')}>
                    Reindex Records
                </Button>
            </Col>
            <Col>
                <Button type='link' onClick={() => changePage('syncplicity_folders')}>
                    Syncplicity Folders
                </Button>
            </Col>

        </Row>
    )
}
