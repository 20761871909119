import React from 'react'
import { Col, Layout, Row, Space, Table, Typography } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import dayjs from 'dayjs';
import { useUserStore } from '../../context/UserStore';

const { useState, useEffect } = React;
const { Header, Footer, Sider, Content } = Layout;
const { Text, Title } = Typography;

export default function SyncplicityLogs(props) {
    const [syncplicityFolderErrorLog, setSyncplicityFolderErrorLog] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const user = useUserStore((state) => state.user);

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}syncplicity_folders/${props.syncplicity_folder_id}/logs`, {
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            if (response.logs.data.length > 0) {
                setIsLoading(false);
                setSyncplicityFolderErrorLog(response.logs.data)
            }
        }).catch(error => {
            console.log(error)
        });
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            sorter: (a, b) => (a.id > b.id ? 1 : -1),
            render: (id) => `${id}`,
            width: "5%"
        },
        {
            title: 'Log Type',
            dataIndex: 'attributes.log_type',
            sorter: (a, b) => (a.attributes.log_type > b.attributes.log_type ? 1 : -1),
            render: (id, record) => {
                return (
                    record.attributes.log_type === "error" ? <Text >{record.attributes.log_type}</Text> : <Text>{record.attributes.log_type}</Text>
                )
            }
        },
        {
            title: 'Error Message',
            dataIndex: 'attributes.error_message',
            render: (id, record) => {
                return (
                    <Text type='danger'>{record.attributes.error_message}</Text>
                )
            }
        },
        {
            title: 'Message',
            dataIndex: 'attributes.message',
            render: (id, record) => {
                return `${record.attributes.message}`
            }
        },
        {
            title: 'Created At',
            dataIndex: 'attributes.created_at',
            render: (id, record) => {
                return `${dayjs(record.attributes.created_at).format('MM/DD/YYYY')}`
            }
        }
    ]

    useEffect(() => {
        customFetch({});
    }, []);

    return (
        <Content>
            <Header style={{ backgroundColor: '#ffffff' }}>
                <Row>
                    <Col xs={12}>
                        <Title>Logs</Title>
                    </Col>
                </Row>
            </Header>
            <Row>
                <Col xs={24}>
                    {(
                        syncplicityFolderErrorLog.length > 0 &&
                        <>
                            <Table
                                style={{ display: 'flex', flex: 1, margin: 10, width: '100%' }}
                                columns={columns}
                                dataSource={syncplicityFolderErrorLog}
                                loading={isLoading}
                                rowKey="id"
                            />
                        </>
                    )}
                    {(syncplicityFolderErrorLog.length === 0 && !isLoading && <Paragraph>No Logs Found</Paragraph>)}
                </Col>
            </Row>
        </Content>
    )
}
