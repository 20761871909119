import React from 'react'
import { Col, Layout, Row, Space, Table, Typography } from 'antd';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import dayjs from 'dayjs';
import { useUserStore } from '../../context/UserStore';


const { useState, useEffect } = React;
const { Header, Footer, Sider, Content } = Layout;
const { Paragraph, Text, Title } = Typography;


export default function SyncplicityFiles(props) {
    const [syncplicityFiles, setSyncplicityFiles] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const user = useUserStore((state) => state.user);

    const customFetch = async (params = {}) => {
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}syncplicity_folders/${props.syncplicity_folder_id}/syncplicity_files`, {
            method: "get",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            if (response.syncplicity_files.data.length > 0) {
                setIsLoading(false);
                setSyncplicityFiles(response.syncplicity_files.data)
            }

        }).catch(error => {
            console.log(error)
        });
    };

    const formatDateTimeForDisplay = (dateTime) => {
        const datetimeString = dateTime;
        const date = dayjs.utc(datetimeString);
        return date.format("MM/DD/YYYY hh:mm A")
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            sorter: (a, b) => (a.id > b.id ? 1 : -1),
            render: (id) => `${id}`,
            width: "5%"
        },
        {
            title: 'Version',
            dataIndex: 'attributes.version',
            render: (id, record) => {
                return (
                    <Text>{record.attributes.version}</Text>
                )
            }
        },
        {
            title: 'Syncplicity File ID',
            dataIndex: 'attributes.file_id',
            render: (id, record) => {
                return (
                    <Text>{record.attributes.file_id}</Text>
                )
            }
        },
        {
            title: 'File Name',
            dataIndex: 'attributes.name',
            render: (id, record) => {
                return <Text>{record.attributes.name}</Text>
            }
        },
        {
            title: 'Created At',
            dataIndex: 'attributes.created_at',
            render: (id, record) => {
                return `${formatDateTimeForDisplay(record.attributes.created_at)}`
            }
        }
    ]

    useEffect(() => {
        customFetch({});
    }, []);

    return (
        <Content>
            <Header style={{ backgroundColor: '#ffffff' }}>
                <Row>
                    <Col xs={24}>
                        <Title>Files</Title>
                    </Col>
                </Row>
            </Header>
            <Row>
                <Col xs={24}>
                    {(
                        syncplicityFiles.length > 0 &&
                        <>

                            <Table
                                style={{ display: 'flex', flex: 1, margin: 10, width: '100%' }}
                                columns={columns}
                                dataSource={syncplicityFiles}
                                loading={isLoading}
                                rowKey="id"
                            />
                        </>
                    )}
                    {(syncplicityFiles.length === 0 && !isLoading && <Paragraph>No Files Found</Paragraph>)}
                </Col>
            </Row>
        </Content>
    )
}
