import React, { useState, useEffect } from 'react';
import { AutoComplete, Button, Col, DatePicker, Input, Layout, Row, Select, Space, Table, Typography, Card } from 'antd';
import { SearchOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import RecrdsSubNavBar from '../Shared/RecrdsSubNavBar';
import { useUserStore } from '../../context/UserStore';

const { Header, Content } = Layout;
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

export default function BirthRecords() {
    const navigate = useNavigate();
    dayjs.extend(utc);

    const [birthRecords, setBirthRecords] = useState([]);
    const [hospitals, setHospitals] = useState([]);
    const [filteredHospitalOptions, setFilteredHospitalOptions] = useState([]);
    const [hospitalSearchValue, setHospitalSearchValue] = useState('');
    const [hospitalFilters, setHospitalFilters] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [order, setOrder] = useState('asc');
    const { RangePicker } = DatePicker;
    const [searchObject, setSearchObject] = useState({
        birth_record_id: '',
        csn: '',
        password: '',
        startDate: '',
        endDate: '',
        hospital_id: '',
        is_bereavement: '',
    });
    const [searchText, setSearchText] = useState('');
    const [sort, setSort] = useState('id');
    const [total, setTotal] = useState(1);

    const user = useUserStore((state) => state.user);


    const birthRecordPage = (birth_record) => {
        navigate(`/syncplicity_folder/${birth_record.relationships.syncplicity_folder.data.id}`);
    }

    const createCsvFromSearchResults = async () => {
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}birth_records/create_csv_from_search_results`, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            body: JSON.stringify(searchObject),
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.blob();
        }).then(blob => {
            const link = document.createElement('a');

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            // Set the download attribute of the link to suggest a filename for the downloaded PDF
            link.download = 'downloaded-file.csv';

            // Append the link to the document body and click it to start the download
            document.body.appendChild(link);
            link.click();

            // Clean up: remove the link and revoke the blob URL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log(error)
        });
        setIsLoading(false);
    }

    const createPdfFromSearchResults = async () => {
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}birth_records/create_pdf_from_search_results`, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            body: JSON.stringify(searchObject),
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.blob();
        }).then(blob => {
            const link = document.createElement('a');

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            // Set the download attribute of the link to suggest a filename for the downloaded PDF
            link.download = 'downloaded-file.pdf';

            // Append the link to the document body and click it to start the download
            document.body.appendChild(link);
            link.click();

            // Clean up: remove the link and revoke the blob URL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log(error)
        });
        setIsLoading(false);
    }

    const customFetch = async (page, pageSize) => {
        (typeof page === 'object' || typeof page === 'undefined') ? page = 1 : page = page;
        typeof pageSize === 'undefined' ? pageSize = 25 : pageSize = pageSize;
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}birth_records?page=${page}&per_page=${pageSize}`
            + `&birth_record_id=${searchObject.birth_record_id.trim()}`
            + `&hospital_id=${searchObject.hospital_id}`
            + `&csn=${searchObject.csn.trim()}`
            + `&password=${searchObject.password.trim()}&startDate=${searchObject.startDate.trim()}`
            + `&endDate=${searchObject.endDate.trim()}&sort=${sort}&order=${order}`
            + `&is_bereavement=${searchObject.is_bereavement}`,
            {
                method: "get",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                },
                type: "json"
            }).then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            }).then(response => {

                const newData = response.birth_records.data.map((item) => {
                    return {
                        ...item,
                        key: item.id
                    }
                })

                setBirthRecords(newData);
                setTotal(response.total);
                setPage(response.current_page);
                setPageSize(pageSize);
                setIsLoading(false);
            }).catch(error => {
                console.log(error)
            });

    };

    const getHospitals = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}hospitals`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            setHospitals(response.hospitals.data);
            const filters = response.hospitals.data.map((hospital) => ({
                label: hospital.attributes.name,
                value: hospital.attributes.id.toString(), // Convert ID to string
            }));
            setHospitalFilters(filters);
            setFilteredHospitalOptions(filters);
        }).catch(error => {
            console.log(error)
        });
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            render: (id) => `${id}`,
            width: "5%"
        },
        {
            title: 'Hospital',
            dataIndex: 'attributes.hospital_id',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <Card size="small" style={{ width: 300 }}>
                        <AutoComplete
                            style={{ width: '100%', marginBottom: 8 }}
                            options={filteredHospitalOptions}
                            onSelect={(value, option) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    hospital_id: value
                                }));
                                setSelectedKeys([value]);
                                setHospitalSearchValue(option.label);
                            }}
                            onSearch={(value) => {
                                setHospitalSearchValue(value);
                                const filtered = hospitalFilters.filter(option =>
                                    option.label.toLowerCase().includes(value.toLowerCase())
                                );
                                setFilteredHospitalOptions(filtered);
                            }}
                            value={hospitalSearchValue}
                        >
                            <Input.Search
                                placeholder="Search for hospital"
                                enterButton
                                onSearch={() => {
                                    const selectedHospital = hospitalFilters.find(h => h.label.toLowerCase() === hospitalSearchValue.toLowerCase());
                                    if (selectedHospital) {
                                        setSearchObject(prevState => ({
                                            ...prevState,
                                            hospital_id: selectedHospital.value
                                        }));
                                        setSelectedKeys([selectedHospital.value]);
                                    }
                                    customFetch(page, pageSize);
                                    confirm();
                                }}
                            />
                        </AutoComplete>
                        <Space>
                            <Button
                                onClick={() => {
                                    clearFilters();
                                    setSearchObject(prevState => ({
                                        ...prevState,
                                        hospital_id: ''
                                    }));
                                    setHospitalSearchValue('');
                                    setFilteredHospitalOptions(hospitalFilters);
                                }}
                                size="small"
                            >
                                Reset
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    customFetch(page, pageSize);
                                    confirm();
                                }}
                                icon={<SearchOutlined />}
                                size="small"
                            >
                                Search
                            </Button>
                        </Space>
                    </Card>
                )
            },
            render: (id, record) => {
                const hospital = hospitals.find(hospital => hospital.attributes.id.toString() === record.attributes.hospital_id.toString());
                return hospital ? hospital.attributes.name : 'Unknown';
            },
            width: "15%"
        },
        {
            title: 'CSN',
            dataIndex: 'attributes.csn',
            render: (id, record) => {
                return `${record.attributes.csn}`
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search by CSN"
                            onPressEnter={() => {
                                customFetch(page, pageSize);
                                confirm();
                            }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    csn: e.target.value
                                }))
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                            }
                            }
                        />
                        <Space>
                            <Button
                                onClick={() => {
                                    customFetch(page, pageSize);
                                    confirm();
                                }}

                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.attributes.csn.toLowerCase().includes(value.toLowerCase()),
            render: (id, record) => {
                return `${record.attributes.csn}`
            },
            width: "5%"
        },
        {
            title: 'Birth Record Id',
            dataIndex: 'attributes.record_id',
            sorter: (a, b) => (a.name > b.name ? 1 : -1),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search by Record ID"
                            onPressEnter={() => {
                                customFetch(page, pageSize);
                                confirm();
                            }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    birth_record_id: e.target.value
                                }))
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                // confirm({ closeDropDown: false });
                            }
                            }
                        />
                        <Space space='small'>
                            <Button
                                onClick={() => {
                                    customFetch(page, pageSize);
                                    confirm();
                                }}

                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => {
                                    clearFilters()
                                    setSearchObject(prevState => ({
                                        ...prevState,
                                        birth_record_id: ''
                                    }));
                                }}

                                className="btn btn-default">
                                Reset
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.attributes.record_id.toLowerCase().includes(value.toLowerCase()),
            render: (id, record) => {
                return `${record.attributes.record_id}`
            }
        },
        {
            title: 'Weight',
            dataIndex: 'attributes.weight_converted',
            render: (id, record) => {
                return `${record.attributes.weight_converted}`
            },
        },
        {
            title: 'Length',
            dataIndex: 'attributes.length_converted',
            render: (id, record) => {
                return `${record.attributes.length_converted}`
            },
        },
        {
            title: 'Bereavement',
            dataIndex: 'attributes.is_bereavement',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Select
                            style={{ width: 200 }}
                            onChange={(value) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    is_bereavement: value
                                }));
                                setSelectedKeys(value ? [value] : []);
                            }}
                            onBlur={() => {
                                customFetch(page, pageSize);
                                confirm();
                            }}
                            options={[
                                { label: 'All', value: '' },
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: 'false' }
                            ]}
                            value={searchObject.is_bereavement}
                        />
                        <Space>
                            <Button
                                onClick={() => {
                                    clearFilters();
                                    setSearchObject(prevState => ({
                                        ...prevState,
                                        is_bereavement: ''
                                    }));
                                }}
                                className="btn btn-default">
                                Reset
                            </Button>
                            <Button
                                onClick={() => {
                                    customFetch(page, pageSize);
                                    confirm();
                                }}
                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                        </Space>
                    </div>
                )
            },
            render: (id, record) => {
                return record.attributes.is_bereavement ? 'Yes' : 'No'
            }
        },
        {
            title: 'Captured At',
            dataIndex: 'attributes.captured_at',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <>
                        <RangePicker onCalendarChange={
                            (dates, dateStrings, info) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    startDate: dateStrings[0],
                                    endDate: dateStrings[1]
                                }))
                            }
                        }></RangePicker>
                        <Space>
                            <Button
                                onClick={() => {
                                    customFetch(page, pageSize);
                                    confirm();
                                }}

                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                        </Space>
                    </>
                )
            },
            render: (id, record) => {
                const datetimeString = record.attributes.captured_at;
                const date = dayjs.utc(datetimeString);
                return (
                    date.format("MM/DD/YYYY hh:mm A")
                )
            }
        },
        {
            title: 'Born At',
            dataIndex: 'attributes.born_at',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <>
                        <RangePicker onCalendarChange={
                            (dates, dateStrings, info) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    startDate: dateStrings[0],
                                    endDate: dateStrings[1]
                                }))
                            }
                        }></RangePicker>
                        <Space>
                            <Button
                                onClick={() => {
                                    customFetch(page, pageSize);
                                    confirm();
                                }}

                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                        </Space>
                    </>
                )
            },
            render: (id, record) => {
                const datetimeString = record.attributes.born_at;
                const date = dayjs.utc(datetimeString);

                return (
                    date.format("MM/DD/YYYY hh:mm A")
                )
            }
        },
        {
            title: 'Password',
            dataIndex: 'attributes.password',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search by Password"
                            onPressEnter={() => {
                                customFetch(page, pageSize);
                                confirm();
                            }}
                            // onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    password: e.target.value
                                }))
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                            }
                            }
                        />
                        <Space>
                            <Button
                                onClick={() => {
                                    customFetch();
                                    confirm();
                                }}

                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.attributes.password.toLowerCase().includes(value.toLowerCase()),
            render: (id, record) => {
                return `${record.attributes.password}`
            }
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (id, record) => {
                return (
                    <Button onClick={() => birthRecordPage(record)}>View Record</Button>
                )
            }
        }
    ]

    useEffect(() => {
        getHospitals();
        customFetch({});
    }, []);

    useEffect(() => {
        setFilteredHospitalOptions(hospitalFilters);
    }, [hospitalFilters]);

    return (
        <Layout className="min-h-screen">
            <RecrdsSubNavBar />
            <Header className="bg-purple-600 p-4">
                <Row align="middle">
                    <Col span={12}>
                        <Title level={2} className="text-white m-0">Birth Records</Title>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            loading={isLoading}
                            onClick={() => createCsvFromSearchResults()}
                        >
                            Download {total} Results
                        </Button>
                    </Col>
                </Row>
            </Header>
            <Content className="p-6 bg-gray-100">
                <Card className="mb-6">
                    <Space direction="vertical" size="middle" className="w-full">
                        <Text>
                            <InfoCircleOutlined className="mr-2" />
                            Birth Records have been indexed/processed by the system and a record exists for them in the database. Unlike Syncplicity Folders, this does indicate that the process was 100% successful, but instead that the record has been seen by the bots and the import process has at least begun. View the record to get all information about the record.
                        </Text>
                        <Text>
                            <InfoCircleOutlined className="mr-2" />
                            Syncplicity Folders indicate that the process at least started. If the Migrated column indicates True, then the process was completed successfully. A False status indicates that it hasn't completed, but not necessarily that there was an Error. The Error column will inform you of that.
                        </Text>
                        <Text>
                            <InfoCircleOutlined className="mr-2" />
                            View the record to get all the information about a record. If the record has any errors, you will find logs that will help inform you as to what went wrong.
                        </Text>
                        <Text strong>Total Records Count: {total}</Text>
                    </Space>
                </Card>

                <Table
                    columns={columns}
                    dataSource={birthRecords}
                    loading={isLoading || hospitals.length === 0}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        onChange: (page, pageSize) => {
                            customFetch(page, pageSize)
                        }
                    }}
                    rowKey="id"
                    className="bg-white shadow-md rounded-lg"
                />
            </Content>
        </Layout>
    )
}
