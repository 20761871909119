import React, { useState } from "react";
import "./styles/App.less";
import { Route, Routes, Navigate } from "react-router-dom";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import NoMatch from "./components/NoMatch/NoMatch";
import Preferences from "./components/Preferences/Preferences";
import useToken from './components/useToken';
import User from "./components/User/User";
import NavBar from './components/Shared/NavBar';
import Dashboard from './components/Dashboard/Dashboard';
import ErrorRecords from "./components/ErrorRecords/ErrorRecords";
import BirthRecord from "./components/BirthRecord/BirthRecord";
import BirthRecords from "./components/BirthRecords/BirthRecords";
import ErrorRecord from "./components/ErrorRecord/ErrorRecord";
import MissingRecordsReport from "./components/MissingRecordsReport/MissingRecordsReport";
import ReindexRecords from "./components/ReindexRecords/ReindexRecords";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import SyncplicityFolder from "./components/SyncplicityFolder/SyncplicityFolder";
import SyncplicityFolders from "./components/SyncplicityFolders/SyncplicityFolders";
import UserAuthContainer from "./components/UserAuthContainer/UserAuthContainer";
import { useUserStore } from './context/UserStore';

function App() {
  const user = useUserStore((state) => state.user);
  if (!user.token) {
    return <UserAuthContainer />;
  }

  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/user/:id" element={<User token={user.token} />} />
        <Route path="/birth_record/:id" element={<BirthRecord token={user.token} />} />
        <Route path="/birth_records" element={<BirthRecords />} />
        <Route path='/change-password' element={<ChangePassword token={user.token} />} />
        <Route path="/error_record/:id" element={<ErrorRecord token={user.token} />} />
        <Route path="/error_records" element={<ErrorRecords />} />
        <Route path='/missing_records' element={<MissingRecordsReport />} />
        <Route path="/reindex_records" element={<ReindexRecords />} />
        <Route path="/syncplicity_folder/:id" element={<SyncplicityFolder token={user.token} />} />
        <Route path="/syncplicity_folders" element={<SyncplicityFolders />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/preferences" element={<Preferences useToken={useToken} />} />
        <Route path="/reset-password" element={<ResetPassword token={user.token} />} />
        <Route path="/default" render={() => <Navigate to="/" />} />
        <Route element={<NoMatch />} />
      </Routes>
    </div>
  );
}

export default App;
