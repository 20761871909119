import React, { useState, useEffect } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import RecrdsSubNavBar from '../Shared/RecrdsSubNavBar';

const { Header, Content } = Layout;
const { Text, Title } = Typography;

function ErrorRecord() {
    const [errorRecord, setErrorRecord] = useState(null);

    useEffect(() => {
        // Get the error record ID from the URL
        const errorRecordId = window.location.pathname.split('/').pop();

        // Fetch the error record data from the API
        fetch(`${process.env.REACT_APP_API_URL}error_records/${errorRecordId}`)
            .then(response => response.json())
            .then(data => setErrorRecord(data.error_folder.data))
            .catch(error => console.error(error));
    }, []);

    return (
        <div>
            {errorRecord ? (
                <Layout>
                    <RecrdsSubNavBar />
                    <Header style={{ backgroundColor: '#ffffff' }}>
                        <Row>
                            <Col xs={24}>
                                <Title>Error Record</Title>
                            </Col>
                        </Row>
                    </Header>
                    <Content>
                        <>
                            {(
                                ((errorRecord !== null) && (errorRecord.attributes !== undefined)) ?
                                    <>
                                        <Row>
                                            <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                                <p><Text strong>Birth Record ID:</Text> {errorRecord.attributes.name}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                                <p><Text strong>Birth Year:</Text> {errorRecord.attributes.birth_year}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                                <p><Text strong>Folder Path:</Text> {errorRecord.attributes.folder_path}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                                {/* Loop Through error_reasons */}
                                                <Text strong>Error Reasons:</Text>
                                                <ul>
                                                    {errorRecord.attributes.error_reasons.map((errorReason, index) => (
                                                        <li key={index}>{errorReason}</li>
                                                    ))}
                                                </ul>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                                <Text strong>Original Birth Record XML (if available):</Text>
                                                <p dangerouslySetInnerHTML={{ __html: errorRecord.attributes.original_xml }}></p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} md={12} lg={12} xl={12} offset={1}>
                                                <Text strong>Formated Birth Record XML (if available):</Text>
                                                <p dangerouslySetInnerHTML={{ __html: errorRecord.attributes.formatted_original_xml }}></p>
                                            </Col>
                                        </Row>

                                    </>
                                    : <Text >No Birth Record Found</Text>
                            )}
                        </>
                    </Content>
                </Layout>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default ErrorRecord;
