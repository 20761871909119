import React from 'react'
import { Button, Col, Input, Layout, Row, Space, Table, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import RecrdsSubNavBar from '../Shared/RecrdsSubNavBar';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../../context/UserStore';

const { useState, useEffect } = React;
const { Header, Content } = Layout;
const { Text, Title } = Typography;

export default function MissingRecordsReport() {
    const navigate = useNavigate();

    const [missingRecords, setMissingRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [order, setOrder] = useState('asc');
    const [searchText, setSearchText] = useState('');
    const [sort, setSort] = useState('id');
    const [total, setTotal] = useState(1);
    const [searchObject, setSearchObject] = useState({
        record_id: '',
    });

    const user = useUserStore((state) => state.user);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            render: (id) => `${id}`,
            width: "5%"
        },
        {
            title: 'Record Id',
            dataIndex: 'record_id',
            sorter: (a, b) => (a.record_id > b.record_id ? 1 : -1),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search by Record ID"
                            onPressEnter={() => {
                                customFetch(page, pageSize);
                                confirm();
                            }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    record_id: e.target.value
                                }))
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                // confirm({ closeDropDown: false });
                            }
                            }
                        />
                        <Space>
                            <Button
                                onClick={() => {
                                    customFetch(page, pageSize);
                                    confirm();
                                }}

                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => { clearFilters() }}

                                className="btn btn-default">
                                Reset
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.attributes.record_id.toLowerCase().includes(value.toLowerCase()),
            render: (id, record) => {
                return `${record.attributes.record_id}`
            },
            width: "15%"
        }
    ]

    const customFetch = async (page, pageSize) => {
        setIsLoading(true);
        (typeof page === 'object' || typeof page === 'undefined') ? page = 1 : page = page;
        typeof pageSize === 'undefined' ? pageSize = 25 : pageSize = pageSize;
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}missing_records_reports?page=${page}&per_page=${pageSize}`
            + `&sort=${sort}&order=${order}`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {

            const newData = response.records.data.map((item) => {
                return {
                    ...item,
                    key: item.id
                }
            })

            setMissingRecords(newData);
            setTotal(response.total);
            setPage(response.current_page);
            setPageSize(pageSize);
            setIsLoading(false);
        }).catch(error => {
            console.log(error)
        });
    };

    useEffect(() => {
        customFetch({});
    }, []);

    return (
        <Layout>
            <RecrdsSubNavBar />
            <Space>
                <Header style={{ backgroundColor: '#ffffff' }}>
                    <Row>
                        <Col xs={24}>
                            <Title>Missing Birth Records</Title>
                        </Col>
                    </Row>

                </Header>

            </Space>
            <Content>
                <Row>
                    <Col xs={24}>
                        <Text>Total Missing Birth Records: {total}</Text>
                    </Col>
                </Row>
                <Table
                    style={{ display: 'flex', flex: 1, margin: 10, width: '100%' }}
                    columns={columns}
                    dataSource={missingRecords}
                    loading={isLoading}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange: (page, pageSize) => {
                            customFetch(page, pageSize)
                        }
                    }}
                    rowKey="id"
                />
            </Content>
        </Layout>
    )
}
