import React from 'react'
import { NavLink } from "react-router-dom";
import useToken from '../useToken';
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import { useUserStore } from '../../context/UserStore';
import useStickyState from '../Shared/UseStickyState';

const { useState, useEffect } = React;

export default function NavBar(props) {
    const user = useUserStore((state) => state.user);
    const me = useUserStore((state) => state.me);

    const navigate = useNavigate();

    const logOutUser = () => {
        localStorage.removeItem("access-token");
        localStorage.removeItem("uid");
        localStorage.removeItem("client");
        localStorage.removeItem("UserObject");
        navigate("/login");
    }

    return (
        <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-purple-500 mb-3">
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                    <a
                        className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
                        href="/"
                    >
                        Certascan Hospital Portal
                    </a>
                </div>
                <div
                    className={
                        "lg:flex flex-grow items-center"
                    }
                    id="example-navbar-danger"
                >
                    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                        <li className="nav-item">
                            <NavLink
                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"


                                to="/">
                                <i className="fab fa-facebook-square text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Home</span>
                            </NavLink>
                        </li>
                        {user == null &&
                            <li className="nav-item">
                                <NavLink
                                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"


                                    to="/login">
                                    <i className="fab fa-facebook-square text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Login</span>
                                </NavLink>
                            </li>
                        }
                        {user != null &&
                            <>
                                <li className="nav-item">
                                    <NavLink
                                        className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"


                                        to="/preferences">
                                        <i className="fab fa-facebook-square text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Preferences</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"


                                        to="/dashboard">
                                        <i className="fab fa-facebook-square text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Dashboard</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <Button className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" onClick={() => logOutUser()}>Logout</Button>
                                </li>
                            </>
                        }


                    </ul>
                </div>
            </div>
        </nav>
    )
}
