import React from 'react'
import { Button, Col, Input, Layout, Row, Space, Table, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import RecrdsSubNavBar from '../Shared/RecrdsSubNavBar';
import { useUserStore } from '../../context/UserStore';

const { useState, useEffect } = React;
const { Header, Content } = Layout;
const { Text, Title } = Typography;

export default function SyncplicityFolders() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [syncplicityFolders, setSyncplicityFolders] = useState([]);
    const [searchObject, setSearchObject] = useState({
        birth_record_id: '',
        password: ''
    });
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [sort, setSort] = useState('id');
    const [order, setOrder] = useState('asc');
    const [total, setTotal] = useState(1);


    const syncplicityFolderPage = (id) => {
        navigate(`/syncplicity_folder/${id}`);
    }

    const user = useUserStore((state) => state.user);

    const customFetch = async (page, pageSize) => {
        typeof page === 'object' ? page = 1 : page = page;
        typeof pageSize === 'undefined' ? pageSize = 25 : pageSize = pageSize;
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}syncplicity_folders?page=${page}`
            + `&per_page=${pageSize}`
            + `&birth_record_id=${searchObject.birth_record_id.trim()}`
            + `&password=${searchObject.password.trim()}`
            + `&sort=${sort} &order=${order}`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {

            const newData = response.syncplicity_folders.data.map((item) => {
                return {
                    ...item,
                    key: item.id
                }
            })
            setSyncplicityFolders(newData);
            setTotal(response.total)
            setPage(response.current_page);
            setPageSize(pageSize);
            setIsLoading(false);
        }).catch(error => {
            console.log(error)
        });

    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            sorter: (a, b) => (a.id > b.id ? 1 : -1),
            render: (id) => `${id}`,
            width: "5%"
        },
        {
            title: 'Birth Record Id',
            dataIndex: 'attributes.name',
            sorter: (a, b) => (a.name > b.name ? 1 : -1),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search by Record ID"
                            onPressEnter={() => {
                                customFetch(page, pageSize);
                                confirm();
                            }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSearchObject(prevState => ({
                                    ...prevState,
                                    birth_record_id: e.target.value
                                }))
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                // confirm({ closeDropDown: false });
                            }
                            }
                        />
                        <Space>
                            <Button
                                onClick={() => {
                                    customFetch(page, pageSize);
                                    confirm();
                                }}

                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => {
                                    clearFilters()
                                    setSearchObject(prevState => ({
                                        ...prevState,
                                        birth_record_id: ''
                                    }))
                                }}

                                className="btn btn-default">
                                Reset
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.attributes.name.toLowerCase().includes(value.toLowerCase()),
            render: (id, record) => {
                return `${record.attributes.name}`
            }
        },
        {
            title: 'Deleted',
            dataIndex: 'attributes.deleted',
            render: (id, record) => {
                return (
                    record.attributes.deleted ? <Text >True</Text> : <Text type="success">False</Text>
                )
            },
        },
        {
            title: 'Empty',
            dataIndex: 'attributes.empty_folder',
            render: (id, record) => {
                return (
                    record.attributes.empty_folder ? <Text >True</Text> : <Text type="success">False</Text>
                )
            },
        },
        {
            title: 'Error',
            dataIndex: 'attributes.error',
            render: (id, record) => {
                return (
                    record.attributes.error ? <Text >True</Text> : <Text type="success">False</Text>
                )
            },
        },
        {
            title: 'In Error Folder',
            dataIndex: 'attributes.in_error_folder',
            render: (id, record) => {
                return (
                    record.attributes.in_error_folder ? <Text >True</Text> : <Text type="success">False</Text>
                )
            },
        },
        {
            title: 'Bereavement',
            dataIndex: 'attributes.is_bereavement',
            render: (id, record) => {
                return `${record.attributes.is_bereavement}`
            }
        },
        {
            title: 'Migrated',
            dataIndex: 'attributes.migrated',
            sorter: (a, b) => (a.migrated > b.migrated ? 1 : -1),
            render: (id, record) => {
                return (
                    record.attributes.migrated ? <Text type="success">True</Text> : <Text >False</Text>
                )
            },
            width: "5%"
        },
        {
            title: 'Updated',
            dataIndex: 'attributes.updated_at',
            sorter: (a, b) => (a.updated_at > b.updated_at ? 1 : -1),
            render: (id, record) => {
                return (
                    dayjs(record.attributes.updated_at, "YYYY-MM-DD").format("MM/DD/YYYY")
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (id, record) => {
                return (
                    <Button onClick={() => syncplicityFolderPage(record.id)}>View Record</Button>
                )
            }
        }
    ]

    useEffect(() => {
        customFetch({});
    }, []);

    return (
        <Layout>
            <RecrdsSubNavBar />
            <Header style={{ backgroundColor: '#ffffff' }}>
                <Row>
                    <Col xs={12}>
                        <Title>Syncplicity Folders</Title>
                    </Col>
                </Row>
            </Header>
            <Content>
                <Row>
                    <Col xs={12} offset={1}>
                        <p>Birth Records have been indexed/processed by the system and a record exists for them in the database. Unlike Syncpliicy Folders, this does inidicate that the process was 100% successful, but instead that the record has been seen by the bots and the import process has at least begun. View the record to get all information about the record.</p>
                        <p>Syncplicity Folders indicate that the process at least started. If the Migrated column indicates True, then the process was completed successfully. A False status indicates that it hasn't completed, but not necessarily that there was an Error. The Error column will inform you of that.</p>
                        <p>View the record to get all the information about a record. If the record has any errors, you will find logs that will help informm you as to what went wrong.</p>
                        <p>Total Records Count: {total}</p>
                    </Col>
                </Row>
                <Table
                    style={{ display: 'flex', flex: 1, margin: 10, width: '100%' }}
                    columns={columns}
                    dataSource={syncplicityFolders}
                    loading={isLoading}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange: (page, pageSize) => {
                            customFetch(page, pageSize)
                        }
                    }}
                    rowKey="id"
                />
            </Content>
        </Layout>
    )
}
